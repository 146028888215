<template>
  <div>
    <TheNavbar />

    <v-container fluid class="py-0 px-0">
      <transition name="fade" mode="out-in">
        <router-view />
      </transition>

      <!-- Auto logout dialog -->
      <v-dialog
        v-model="dialog"
        scrollable
        max-width="85%"
        transition="dialog-transition"
      >
        <v-card>
          <v-card-text class="py-3">
            <h2 class="headline my-3">Are you sure you want to log out?</h2>
            <v-row>
              <v-col cols="6">
                <v-btn block color="success">Yes</v-btn>
              </v-col>
              <v-col cols="6">
                <v-btn block outlined color="error">No</v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-container>

    <div class="d-none d-sm-block space-divider"></div>

    <TheFooter />
  </div>
</template>

<script>
import TheNavbar from "@/components/TheNavbar";
import TheFooter from "@/components/TheFooter";

export default {
  components: {
    TheNavbar,
    TheFooter,
    // BottomNavigaton
  },
  data: () => ({
    dialog: false,
  }),
  created() {
    this.$store.commit("EXPIRE_LOGIN");
    if(this.$store.state.user !== ''){
    this.$store.dispatch("sessionStatus").then(() => {
      this.$store.dispatch("fetchAppointments");
      this.$store.dispatch("fetchAppointmentTypes");
      this.$store.dispatch("myTenanciesPropertyTypes");
      // this.$store.dispatch('myTenancies', {
      //     minPrice: '',
      //     maxPrice: '',
      //     minBeds: '',
      //     maxBeds: '',
      //     propertyType: ''
      // })
    });
    }
    else{
      
      this.$router.push('/');
    }
  },
};
</script>

<style>
.space-divider {
  width: 100%;
  height: 50px;
}
</style>