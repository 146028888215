<template>
  <div id="inspire">
    <nav>
      <!-- Nav Drawer -->
      <v-navigation-drawer
        :expand-on-hover="$vuetify.breakpoint.mdAndUp"
        v-model="drawer"
        app
        :mini-variant.sync="mini"
        floating
        width="310"
        mobile-breakpoint="960"
        color="grey lighten-4"
      >
        <v-list class="mt-0 pt-0" dense>
          <v-list-item
            active-class="secondary darken-1 white--text"
            class="white-text"
            :class="item.hideOnLg ? 'd-lg-none' : ''"
            router
            :to="item.link"
            v-for="item in links"
            :key="item.name"
            link
          >
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ item.name }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-group
            @click.stop
            v-for="(link, index) in newLinks"
            :key="index"
            active-class="secondary white--text"
          >
            <template v-slot:activator @click.stop>
              <v-list-item-icon active-class="secondary white--text">
                <v-icon>{{ link.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ link.groupName }}</v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item
              active-class="secondary white--text"
              v-for="(item, index) in link.sublinks"
              :key="index"
              router
              :to="item.link"
            >
              <v-list-item-icon>
                <v-icon class="">
                  <!-- {{item.icon}} -->
                  mdi-circle-small
                </v-icon>
              </v-list-item-icon>
              <v-list-item-title>
                {{ item.name }}
              </v-list-item-title>
            </v-list-item>
          </v-list-group>
        </v-list>
      </v-navigation-drawer>

      <!-- Appbar begins -->
      <v-app-bar app color="primary" dark flat height="48">
        <!-- Hamburger menu -->
        <v-app-bar-nav-icon
          class="d-flex d-md-none"
          @click.stop="drawer = !drawer"
        />
        <v-toolbar-title class="mx-auto px-0">
          <router-link id="router-hyperlink" :to="{ name: 'dashboard' }">
            <v-img
              class="ml-n5 logoPng"
              max-height="48"
              max-width="100"
              src="@/assets/app_logo_inverted.png"
            ></v-img>
          </router-link>
        </v-toolbar-title>

        <v-spacer class=""></v-spacer>
        <v-toolbar-items>
          <!-- Refresh btns -->
          <v-btn
            v-if="
              this.$route.path === '/negapp/calendar' ||
              this.$route.path === '/negapp/dashboard'
            "
            small
            class="d-none d-md-flex"
            @click="refreshAppointments"
            text
            color="white"
            width="63"
          >
            <v-icon small>mdi-refresh</v-icon>
          </v-btn>
          <!-- Release notes menu -->
          <TheReleaseNotes />

          <!-- Logout menu -->
          <v-menu offset-y>
            <template v-slot:activator="{ on }">
              <v-btn color="white" dark v-on="on" outlined text>
                <v-icon>mdi-account</v-icon>
              </v-btn>
            </template>
            <v-list class="pt-0">
              <v-list-item class="light-blue">
                <v-list-item-title>
                  <span class="white--text">{{
                    $store.state.sessionData.ActiveUser
                  }}</span>
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                href="https://agentos.canny.io/agentos-negapp-requests"
                target="_blank"
              >
                <v-list-item-title>
                  <span class="grey--text text--darken-1"
                    >Request a feature</span
                  >
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                href="https://agentos.typeform.com/to/svHzYgZe"
                target="_blank"
              >
                <v-list-item-title>
                  <span class="grey--text text--darken-1">Give feedback</span>
                </v-list-item-title>
              </v-list-item>
              <v-list-item @click="logout">
                <v-list-item-title>
                  <span class="grey--text text--darken-1">Log Out</span>
                  <v-icon small right>mdi-logout-variant</v-icon>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-toolbar-items>
      </v-app-bar>
    </nav>
  </div>
</template>

<script>
import TheReleaseNotes from "@/components/TheReleaseNotes.vue";

export default {
  name: "the-nav-bar",
  components: {
    TheReleaseNotes,
  },
  props: {
    source: String,
  },
  data: () => ({
    mini: true,
    drawer: null,
    links: [
      {
        name: "Home",
        icon: "mdi-view-dashboard",
        link: "/negapp/dashboard",
        hideOnLg: false,
      },
      {
        name: "Calendar",
        icon: "mdi-calendar-month-outline",
        link: "/negapp/calendar",
        hideOnLg: true,
      },
      {
        name: "Messages",
        icon: "mdi-email-outline",
        link: "/negapp/messages",
        hideOnLg: false,
      },
    ],
    newLinks: [
      {
        groupName: "Office",
        link: "",
        icon: "mdi-view-dashboard-outline",
        sublinks: [
          {
            name: "My properties",
            link: "/negapp/properties",
            // icon: 'mdi-home-city'
          },
          {
            name: "Portal leads (coming soon)",
            link: "",
            // icon: 'mdi-home-city'
          },
          {
            name: "My buyers (BETA version)",
            link: "/negapp/buyers",
          },
          {
            name: "My applicants (BETA version)",
            link: "/negapp/applicants",
          },
        ],
      },
      {
        groupName: "Rent",
        link: "",
        icon: "mdi-home-outline",
        sublinks: [
          {
            name: "Online application",
            link: "/negapp/application",
          },
        ],
      },
      {
        groupName: "Sales instructions",
        link: "",
        icon: "mdi-home",
        sublinks: [
          {
            name: "Take an offer",
            link: "/negapp/offer",
            // icon: 'mdi-currency-gbp',
          },
          {
            name: "Add a buyer",
            link: "/negapp/buyer",
          },
        ],
      },
      {
        groupName: "Valuations (coming soon)",
        link: "",
        icon: "mdi-chart-line",
        sublinks: [
          {
            name: "Recently sstc/let agreed",
            link: "",
          },
          {
            name: "Recently sold/let",
            link: "",
          },
          {
            name: "Add property",
            link: "",
          },
        ],
      },
      {
        groupName: "Prospecting (coming soon)",
        link: "",
        icon: "mdi-file-search-outline",
        sublinks: [
          {
            name: "My calls",
            link: "",
          },
        ],
      },
    ],
  }),
  methods: {
    refreshAppointments() {
      this.$store.dispatch("fetchAppointments");
    },
    logout() {
      this.$store.dispatch("logout").then(() => {
        this.$router.push("/");
      });
    },
    onResize() {
      // if (window.innerWidth > 600) {
      //   this.drawer = false;
      // }
    },
  },
  mounted() {
    this.onResize();
  },
};
</script>

<style lang="css">
#router-hyperlink {
  text-decoration: none;
}
.logoPng {
  display: block;
  margin: 0 auto;
}
</style>