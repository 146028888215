<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on }">
      <v-btn color="white" dark v-on="on" outlined text>
        <v-icon small>mdi-alert-decagram</v-icon>
      </v-btn>
    </template>
    <v-list class="pt-0">
      <v-list-item class="light-blue">
        <v-list-item-title>
          <span class="white--text" >Release notes</span>
        </v-list-item-title>
      </v-list-item>
      <v-list-item v-for="item in releaseNotes" :key="item.date">
        <v-list-item-title   >
          <v-chip small color="green" dark class="mr-2 my-2">{{ item.state }}</v-chip>
          <span class="text-caption">{{ item.date }}</span>
          <ul v-for="(note, index) in item.notes" :key="index"  class="text-caption">
            <li>{{ note }}</li>
          </ul>
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: 'the-release-notes',
  data() {
    return {
      releaseNotes: [
        {
          date: '31/08/2020',
          state: 'New',
          notes: [
            'View multiple diaries and assign appoinments.',
            'Read, send emails/sms and download attachments.',
            'Add an online application.',
            'Submit a sales offer.',
            'Browse all properties, book appointments/assign to colleague.',
            'Add a new buyer form.',
            'Search for a buyer and link to offer/appointment (Beta)',
            'Search for an applicant and link to application/appointment (Beta)',
          ]
        }
      ]
    }
  },
}
</script>

<style>

</style>