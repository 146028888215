<template>
  <v-footer
    absolute
    class="font-weight-medium"
  >
    <v-row>
      <v-col
        class="d-flex align-center"
        cols="6"
        sm="12"
      >
         <span class="font-weight-light caption">&copy;{{ new Date().getFullYear() }} </span>
         <!-- <span class="font-weight-light">- negApp</span> -->
         <div class="ma-0 pa-0">
           <v-img class="ma-0" width="60px" src="@/assets/agentos-bw-logo.png"></v-img>
         </div>
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
export default {

}
</script>

<style>

</style>